import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocument, QueryCommandInput } from "@aws-sdk/lib-dynamodb";
import {
  User,
  License,
  Transcription,
  UpdateTranscriptionParams,
  TranscriptionStats,
  TranscriptEdit,
  UpdateUserParams,
  EmailValidation,
  Usage,
} from "@/api-lib";
import { StripeInvoice } from "@/api-lib/stripeClient";

interface Env {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
}

export class DbClient {
  private client: DynamoDBDocument;
  private readonly REGION = "us-east-2";
  private readonly TableName = "1transcribe";

  constructor(env?: Env) {
    const rawClient = new DynamoDBClient({
      region: this.REGION,
      credentials: env
        ? {
            accessKeyId: env.AWS_ACCESS_KEY_ID,
            secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
          }
        : undefined,
    });

    this.client = DynamoDBDocument.from(rawClient);
  }

  async setStripeInvoice(invoice: StripeInvoice): Promise<void> {
    const deviceId = invoice.subscription_details.metadata.deviceId;
    const email = invoice.customer_email.toLowerCase().trim();

    const previousUser = await this.getUser(deviceId);

    await Promise.all([
      this.client.put({
        TableName: this.TableName,
        Item: { ...previousUser, email },
      }),
      this.client.put({
        TableName: this.TableName,
        Item: {
          ...invoice,
          email,
          pk: "STRIPE_INVOICE",
          sk: `DEVICE_ID#${invoice.subscription_details.metadata.deviceId}`,
        },
      }),
    ]);
  }

  async getStripeInvoice(deviceId: string): Promise<StripeInvoice | undefined> {
    const response = await this.client.get({
      TableName: this.TableName,
      Key: { pk: "STRIPE_INVOICE", sk: `DEVICE_ID#${deviceId}` },
    });

    if (!response.Item) return;

    return response.Item as StripeInvoice;
  }

  async getStripeInvoiceByEmail(
    email: string
  ): Promise<StripeInvoice | undefined> {
    const response = await this.client.query({
      TableName: this.TableName,
      KeyConditionExpression: "email = :userEmail",
      FilterExpression: "pk = :primaryKey",
      ExpressionAttributeValues: {
        ":userEmail": email.toLowerCase().trim(),
        ":primaryKey": "STRIPE_INVOICE",
      },
      IndexName: "email-index",
    });

    if (response.Items.length === 0) return;

    return response.Items[0] as StripeInvoice;
  }

  async createEmailValidation(params: {
    email: string;
    isValid: boolean;
    status: string;
    apiResponseJson: string;
  }): Promise<EmailValidation> {
    let item: EmailValidation = {
      email: params.email.trim().toLowerCase(),
      isValid: params.isValid,
      status: params.status,
      createdAt: new Date().toISOString(),
      apiResponseJson: params.apiResponseJson,
    };

    await this.client.put({
      TableName: this.TableName,
      Item: { pk: "EMAIL_VALIDATION", sk: item.email, ...item },
    });

    return item;
  }

  async getEmailValidation(
    email: string
  ): Promise<{ isValid: boolean } | null> {
    const [validationResponse, user] = await Promise.all([
      this.client.get({
        TableName: this.TableName,
        Key: { pk: "EMAIL_VALIDATION", sk: email.trim().toLowerCase() },
      }),
      this.getUserByEmail(email.trim().toLowerCase()),
    ]);

    // if (user) return { isValid: true };

    if (!validationResponse.Item) return null;

    return { isValid: (validationResponse.Item as EmailValidation).isValid };
  }

  async getUserByEmail(email: string): Promise<User | undefined> {
    const response = await this.client.query({
      TableName: this.TableName,
      KeyConditionExpression: "email = :userEmail",
      FilterExpression: "sk = :sortKey",
      ExpressionAttributeValues: {
        ":userEmail": email.toLowerCase().trim(),
        ":sortKey": "USER",
      },
      IndexName: "email-index",
    });

    if (response.Items.length === 0) return;

    return response.Items[0] as User;
  }

  async createUser(user: Omit<User, "createdAt" | "updatedAt">): Promise<User> {
    const item: User = {
      ...user,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    await this.client.put({
      TableName: this.TableName,
      Item: { pk: item.deviceId, sk: "USER", ...item },
    });

    return item;
  }

  async setUsage(usage: Omit<Usage, "createdAt">): Promise<Usage> {
    const item: Usage = {
      ...usage,
      createdAt: new Date().toISOString(),
    };

    await this.client.put({
      TableName: this.TableName,
      Item: { pk: item.deviceId, sk: `USAGE#${item.transcriptionId}`, ...item },
    });

    return item;
  }

  async listUsagesByDeviceId({
    deviceId,
  }: {
    deviceId: string;
  }): Promise<Usage[]> {
    let items: Usage[] = [];
    let lastEvaluatedKey = null;
    let params: QueryCommandInput = {
      TableName: this.TableName,
      KeyConditionExpression: "pk = :deviceId and begins_with(sk, :sortKey)",
      ExpressionAttributeValues: {
        ":deviceId": deviceId,
        ":sortKey": "USAGE#",
      },
      ScanIndexForward: false,
    };

    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const response = await this.client.query(params);

      items = items.concat(response.Items as Usage[]);
      lastEvaluatedKey = response.LastEvaluatedKey;
    } while (lastEvaluatedKey);

    return items;
  }

  async listUsagesByIp({ ip }: { ip: string }): Promise<Usage[]> {
    let items: Usage[] = [];
    let lastEvaluatedKey = null;
    let params: QueryCommandInput = {
      TableName: this.TableName,
      IndexName: "ip-index",
      KeyConditionExpression: "ip = :ip",
      ExpressionAttributeValues: { ":ip": ip, ":sortKey": "USAGE#" },
      ExpressionAttributeNames: { "#sortKey": "sk" },
      ScanIndexForward: false,
      FilterExpression: "begins_with(#sortKey, :sortKey)",
    };

    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const response = await this.client.query(params);

      items = items.concat(response.Items as Usage[]);
      lastEvaluatedKey = response.LastEvaluatedKey;
    } while (lastEvaluatedKey);

    return items;
  }

  async listUsagesByEmail({ email }: { email: string }): Promise<Usage[]> {
    let items: Usage[] = [];
    let lastEvaluatedKey = null;
    let params: QueryCommandInput = {
      TableName: this.TableName,
      IndexName: "email-index",
      KeyConditionExpression: "email = :email",
      ExpressionAttributeValues: { ":email": email, ":sortKey": "USAGE#" },
      ExpressionAttributeNames: { "#sortKey": "sk" },
      ScanIndexForward: false,
      FilterExpression: "begins_with(#sortKey, :sortKey)",
    };

    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const response = await this.client.query(params);

      items = items.concat(response.Items as Usage[]);
      lastEvaluatedKey = response.LastEvaluatedKey;
    } while (lastEvaluatedKey);

    return items;
  }

  async updateUser(user: UpdateUserParams): Promise<User> {
    let previousUser = await this.getUserByEmail(user.updates.email);

    if (!previousUser) {
      previousUser = await this.getUser(user.updates.deviceId);
    }

    const item: User = {
      ...previousUser,
      ...user.updates,
      updatedAt: new Date().toISOString(),
    };

    await this.client.put({
      TableName: this.TableName,
      Item: { pk: item.deviceId, sk: "USER", ...item },
    });

    return item;
  }

  async createTranscription(
    transcription: Omit<
      Transcription,
      | "createdAt"
      | "updatedAt"
      | "status"
      | "id"
      | "languageCode"
      | "withSpeakerLabels"
      | "numberOfSpeakers"
    >
  ): Promise<Transcription> {
    const item: Transcription = {
      ...transcription,
      id: `${transcription.deviceId}__${transcription.fileId}`,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: "upload_pending",
    };

    await this.client.put({
      TableName: this.TableName,
      Item: {
        pk: item.deviceId,
        sk: `TRANSCRIPTION#${item.deviceId}__${item.fileId}`,
        ...item,
      },
    });

    return item;
  }

  async updateTranscription(
    params: UpdateTranscriptionParams
  ): Promise<Transcription> {
    const previousTranscription = await this.getTranscription({
      deviceId: params.updates.deviceId,
      fileId: params.updates.fileId,
    });

    if (!previousTranscription) return;

    const item: Transcription = {
      ...previousTranscription,
      ...params.updates,
      updatedAt: new Date().toISOString(),
    };

    await this.client.put({ TableName: this.TableName, Item: item });

    return item;
  }

  async getTranscription({
    deviceId,
    fileId,
  }: {
    deviceId: string;
    fileId: string;
  }): Promise<Transcription | undefined> {
    const response = await this.client.get({
      TableName: this.TableName,
      Key: { pk: deviceId, sk: `TRANSCRIPTION#${deviceId}__${fileId}` },
      ConsistentRead: true,
    });

    if (!response.Item) return;

    return response.Item as Transcription;
  }

  async listTranscriptions({
    deviceId,
    includePending = false,
  }: {
    deviceId: string;
    includePending?: boolean;
  }): Promise<Transcription[]> {
    let items: Transcription[] = [];
    let lastEvaluatedKey = null;
    let params: QueryCommandInput = {
      TableName: this.TableName,
      KeyConditionExpression: "pk = :deviceId and begins_with(sk, :sortKey)",
      ExpressionAttributeValues: {
        ":deviceId": deviceId,
        ":sortKey": "TRANSCRIPTION#",
        ":transcriptionStatus": "upload_pending",
      },
      ExpressionAttributeNames: {
        "#status": "status",
      },
      FilterExpression: "#status <> :transcriptionStatus",
      ScanIndexForward: false,
    };

    if (includePending) {
      params = {
        TableName: this.TableName,
        KeyConditionExpression: "pk = :deviceId and begins_with(sk, :sortKey)",
        ExpressionAttributeValues: {
          ":deviceId": deviceId,
          ":sortKey": "TRANSCRIPTION#",
        },
        ScanIndexForward: false,
      };
    }

    do {
      if (lastEvaluatedKey) {
        params.ExclusiveStartKey = lastEvaluatedKey;
      }

      const response = await this.client.query(params);

      items = items.concat(response.Items as Transcription[]);
      lastEvaluatedKey = response.LastEvaluatedKey;
    } while (lastEvaluatedKey);

    return items;
  }

  async setTranscriptEdit(
    transcriptEdit: Pick<TranscriptEdit, "deviceId" | "fileId" | "transcript">
  ): Promise<TranscriptEdit> {
    let previousTranscriptEdit = await this.getTranscriptEdit({
      deviceId: transcriptEdit.deviceId,
      fileId: transcriptEdit.fileId,
    });

    if (!previousTranscriptEdit) {
      previousTranscriptEdit = {
        deviceId: transcriptEdit.deviceId,
        fileId: transcriptEdit.fileId,
        transcript: {},
        createdAt: new Date().toISOString(),
      };
    }

    const item: TranscriptEdit = {
      ...previousTranscriptEdit,
      transcript: {
        ...previousTranscriptEdit.transcript,
        ...transcriptEdit.transcript,
      },
      updatedAt: new Date().toISOString(),
    };

    await this.client.put({
      TableName: this.TableName,
      Item: {
        pk: item.deviceId,
        sk: `TRANSCRIPT_EDIT#${item.deviceId}__${item.fileId}`,
        ...item,
      },
    });

    return item;
  }

  async getTranscriptEdit({
    deviceId,
    fileId,
  }: Pick<Transcription, "deviceId" | "fileId">): Promise<
    TranscriptEdit | undefined
  > {
    const response = await this.client.get({
      TableName: this.TableName,
      Key: { pk: deviceId, sk: `TRANSCRIPT_EDIT#${deviceId}__${fileId}` },
    });

    if (!response.Item) return;

    return response.Item as TranscriptEdit;
  }

  async getUser(deviceId: string): Promise<User | undefined> {
    const response = await this.client.get({
      TableName: this.TableName,
      Key: { pk: deviceId, sk: "USER" },
      ConsistentRead: true,
    });

    if (!response.Item) return;

    return response.Item as User;
  }

  async getUserByIp(ip: string): Promise<User | undefined> {
    const response = await this.client.query({
      TableName: this.TableName,
      KeyConditionExpression: "ip = :userIp",
      FilterExpression: "sk = :sortKey",
      ExpressionAttributeValues: { ":userIp": ip, ":sortKey": "USER" },
      IndexName: "ip-index",
    });

    if (response.Items.length === 0) return;

    return response.Items[0] as User;
  }

  async increaseUsageInSeconds({
    deviceId,
    usageInSeconds,
  }: {
    deviceId: string;
    usageInSeconds: number;
  }): Promise<void> {
    try {
      const [dbUser, previousStats] = await Promise.all([
        this.getUser(deviceId),
        this.client
          .get({
            TableName: this.TableName,
            Key: { pk: "STATS", sk: "TRANSCRIPTION" },
          })
          .then((response) => response.Item as TranscriptionStats),
      ]);

      if (!dbUser) return;

      const nextUser: User = {
        ...dbUser,
        usageInSeconds: dbUser.usageInSeconds || 0,
        transcriptionCount: dbUser.transcriptionCount || 0,
        updatedAt: new Date().toISOString(),
      };

      nextUser.usageInSeconds = nextUser.usageInSeconds + usageInSeconds;

      nextUser.transcriptionCount = nextUser.transcriptionCount + 1;

      const nextStats: TranscriptionStats = {
        ...previousStats,
        transcriptionUsageInSeconds:
          previousStats.transcriptionUsageInSeconds + usageInSeconds,
        transcriptionCount: previousStats.transcriptionCount + 1,
        updatedAt: new Date().toISOString(),
      };

      await Promise.all([
        this.client.put({ TableName: this.TableName, Item: nextUser }),
        this.client.put({ TableName: this.TableName, Item: nextStats }),
      ]);
    } catch (e) {}
  }

  async getLicenseByUserId(deviceId: string): Promise<License | undefined> {
    const response = await this.client.get({
      TableName: this.TableName,
      Key: { pk: deviceId, sk: "LICENSE" },
    });

    if (!response.Item) return;

    return response.Item as License;
  }

  async getLicenseByEmail(email: string): Promise<License | undefined> {
    const response = await this.client.query({
      TableName: this.TableName,
      KeyConditionExpression: "email = :userEmail",
      FilterExpression: "sk = :sortKey",
      ExpressionAttributeValues: {
        ":userEmail": email.trim(),
        ":sortKey": "LICENSE",
      },
      IndexName: "email-index",
    });

    if (response.Items.length === 0) return;

    return response.Items[0] as License;
  }

  async deleteTranscription({
    deviceId,
    fileId,
  }: Pick<Transcription, "deviceId" | "fileId">): Promise<void> {
    await Promise.all([
      this.client.delete({
        TableName: this.TableName,
        Key: { pk: deviceId, sk: `TRANSCRIPTION#${deviceId}__${fileId}` },
      }),
      this.client.delete({
        TableName: this.TableName,
        Key: { pk: deviceId, sk: `TRANSCRIPT_EDIT#${deviceId}__${fileId}` },
      }),
    ]);
  }
}
