import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { __DEV__ } from "@/constants";

const version = "1.3.21";

if (typeof window !== "undefined" && !__DEV__) {
  const isTest = window.location.href.includes("pages.dev");

  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    service: "1transcribe-web",
    env: isTest ? "test" : "prod",
    version,
    sessionSampleRate: 100,
  });

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "1transcribe-rum",
    env: isTest ? "test" : "prod",
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });
}
